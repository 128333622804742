<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('unofficial_transcript')"
                    :is-filter="false"
                ></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('unofficial_transcript')"
                    :is-filter="false"
                >
                </HeaderMobile>
            </template>
            <b-row>
                <b-col class="d-flex flex-column align-items-center">
                    <b-col lg="4" class="pb-2 d-flex justify-content-center">
                        <b-button size="lg" :disabled="loading" @click="officialTranscript">
                            {{ this.$t('unofficial_transcript') }}
                        </b-button>
                    </b-col>
                    <div class="w-100 border-bottom my-3"></div>

                    <b-col lg="8" class="d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="mb-4">
                            <strong>Bay Atlantic University</strong> has partnered with <strong>Parchment</strong> to
                            securely order and send transcripts.
                            <a href="https://www.parchment.com/u/registration/76230308/institution" target="_blank">Order Transcripts
                                Here</a>
                        </div>
                        <div>
                            <b-img width="200" height="83" src="https://www.sjredwings.org/downloads/icons/parchment_-_new_rectangle_.png" fluid
                                   alt="Responsive image"></b-img>
                        </div>
                        <div class="mt-2">
                            For assistance, check out our <a href="https://docs.google.com/document/d/1JVffguNsh1KTFYF3R3dvx5NlAKqQXHDsa619ztgCk14/edit?pli=1&tab=t.0" target="_blank">Quick Guide</a> and <a href="https://vimeo.com/showcase/9681390" target="_blank">Learner Videos.</a>
                        </div>
                    </b-col>
                </b-col>
            </b-row>

        </app-layout>
    </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import StudentDocumentService from "@/services/StudentDocumentService";
import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('unofficial_transcript')
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        user() {
            return this.$store.getters["auth/getUser"];
        },
    },
    methods: {
        officialTranscript() {
            this.loading=true
            const student_number = this.user.student.student_number
            const studentProgramId = this.user.student?.student_program[0]?.id
            const lang = 'en'
                StudentDocumentService.downloadTranscript(studentProgramId, lang)
                    .then(response => {
                        let fileName = student_number + '-' + this.$t('file_name_transcript') +  '.pdf'
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    })
                    .catch(e => {
                        showErrors(e, null, true)
                    }).finally(this.loading=false)

        }
    },

}
</script>

